<template>
  <div>
    <ApiError :errors="errors"></ApiError>
    <v-data-table :headers="headers" :items="items" :loading="loading" hide-default-footer>
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>Approval Stages</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom v-if="!disabled">
            <template v-slot:activator="{ on: onTooltip, attrs: attrsTooltip }">
              <v-btn fab small v-bind="{ ...attrsTooltip }" v-on="{ ...onTooltip }" @click="onNewItem()">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>New Approval Stage</span>
          </v-tooltip>
          <v-dialog v-model="dialog" v-if="!disabled && editedItem" width="600">
            <v-form @submit.prevent="onSave" v-model="valid">
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col>
                        <v-text-field
                          v-model="editedItem.name"
                          :rules="rules.name"
                          label="Stage Name"
                          class="required"
                        ></v-text-field>
                        <v-text-field v-model="editedItem.description" label="Description"></v-text-field>
                        <v-text-field
                          v-model="editedItem.rankOrder"
                          :rules="rules.rankOrder"
                          type="number"
                          label="Rank / Order"
                          class="required"
                        ></v-text-field>
                        <ParticipantField
                          showKey
                          v-model="editedItem.autoAssignParticipant"
                          label="Auto Assign To"
                        ></ParticipantField>
                        <v-switch label="Final Stage?" v-model="editedItem.finalStage" class="required"></v-switch>
                        <v-switch
                          label="Approved Stage?"
                          v-model="editedItem.approvedStage"
                          class="required"
                        ></v-switch>
                        <v-switch
                          label="Rejected Stage?"
                          v-model="editedItem.rejectedStage"
                          class="required"
                        ></v-switch>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="onClose">{{ $i18n.translate("Cancel") }}</v-btn>
                  <v-btn color="blue darken-1" text :disabled="!valid" @click="onSave">{{
                    $i18n.translate("Save")
                  }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-form>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.autoAssignParticipant="{ item }">
        {{ item.autoAssignParticipant ? item.autoAssignParticipant.fullName : "" }}
      </template>
      <template v-slot:item.approvedStage="{ item }">
        <v-icon v-if="item.approvedStage" color="green">mdi-check</v-icon>
        <v-icon v-else color="red">mdi-close</v-icon>
      </template>
      <template v-slot:item.rejectedStage="{ item }">
        <v-icon v-if="item.rejectedStage" color="green">mdi-check</v-icon>
        <v-icon v-else color="red">mdi-close</v-icon>
      </template>
      <template v-slot:item.finalStage="{ item }">
        <v-icon v-if="item.finalStage" color="green">mdi-check</v-icon>
        <v-icon v-else color="red">mdi-close</v-icon>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon class="mr-2" v-if="!disabled" @click="onEditItem(item)">
          mdi-pencil
        </v-icon>
        <v-icon v-if="!disabled" @click="onDeleteItem(item)">
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ApiError from "../display/ApiError.vue";
import ParticipantField from "../fields/ParticipantField.vue";

export default {
  components: { ApiError, ParticipantField },
  name: "ApprovalStagesTable",
  props: {
    approvalType: Object,
    value: Object,
    disabled: Boolean
  },
  data: () => ({
    loading: false,
    errors: {},
    valid: false,
    dialog: false,
    headers: [
      { text: "Stage Name", value: "name" },
      { text: "Description", value: "description" },
      { text: "Rank", value: "rankOrder", align: "center" },
      { text: "Auto Assign", value: "autoAssignParticipant" },
      { text: "Approved Stage?", value: "approvedStage", align: "center" },
      { text: "Rejected Stage?", value: "rejectedStage", align: "center" },
      { text: "Final Stage?", value: "finalStage", align: "center" },
      { text: "Actions", value: "actions", sortable: false }
    ],
    rules: {
      name: [v => !!v || "Name is required"],
      rankOrder: [v => !!v || "Rank is required"]
    },
    items: [],
    editedItem: {}
  }),

  computed: {
    ...mapGetters(["selectedProgram", "selectedClient"]),
    formTitle() {
      return this.editedIndex === -1 ? "New Approval Stage" : "Edit Approval Stage";
    }
  },

  watch: {
    approvalType() {
      this.getData();
    }
  },

  created() {
    this.getData();
  },

  methods: {
    getData() {
      if (this.approvalType) {
        this.loading = true;
        return this.$api
          .get("/api/approvalStages/byApprovalTypeId/" + this.approvalType.id)
          .then(({ data }) => {
            this.items = data;
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        return Promise.resolve();
      }
    },

    onNewItem() {
      let maxRank = 0;
      this.items.forEach(item => {
        if (maxRank < item.rankOrder) {
          maxRank = item.rankOrder;
        }
      });
      this.editedItem = {
        name: "",
        description: "",
        rankOrder: maxRank + 1,
        finalStage: false,
        approvedStage: false,
        rejectedStage: false
      };
      this.dialog = true;
    },

    onEditItem(item) {
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    onDeleteItem(item) {
      this.errors = {};
      this.loading = true;
      return this.$api
        .delete("/api/approvalStages/" + item.id)
        .then(() => {
          this.getData();
        })
        .catch(error => {
          this.errors = this.$api.getErrorsFromResponse(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onClose() {
      this.dialog = false;
      this.editedItem = null;
    },

    onSave() {
      this.errors = {};
      this.loading = true;
      let service = null;
      this.editedItem.autoAssignParticipant = this.editedItem.autoAssignParticipant
        ? this.$api.getSelfUrl("participants", this.editedItem.autoAssignParticipant)
        : null;
      if (this.editedItem.id) {
        service = this.$api.patch("/api/approvalStages/" + this.editedItem.id, this.editedItem);
      } else {
        this.editedItem.approvalType = this.$api.getSelfUrl("approvalTypes", this.approvalType);
        service = this.$api.post("/api/approvalStages", this.editedItem);
      }
      return service
        .then(() => {
          this.onClose();
          this.getData();
        })
        .catch(error => {
          this.errors = this.$api.getErrorsFromResponse(error);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
