<template>
  <v-card>
    <v-toolbar>
      <v-toolbar-title>{{ $i18n.translate("Approval Type") }} - {{ form.name }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip bottom v-if="$privilege.hasPrivilege('APPROVAL_TYPE_UPDATE') && isEditing && tab == 0">
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="mr-2" fab small @click="onSubmit" v-bind="attrs" :disabled="!valid" v-on="on">
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
        </template>
        <span>{{ $i18n.translate("Save") }}</span>
      </v-tooltip>
      <v-tooltip bottom v-if="$privilege.hasPrivilege('APPROVAL_TYPE_UPDATE') && !isNew">
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="mr-2" fab small @click="onEdit" v-bind="attrs" v-on="on">
            <v-icon v-if="isEditing">mdi-close</v-icon>
            <v-icon v-else>mdi-pencil</v-icon>
          </v-btn>
        </template>
        <span v-if="isEditing">{{ $i18n.translate("Cancel") }}</span>
        <span v-else>{{ $i18n.translate("Edit") }}</span>
      </v-tooltip>

      <template v-slot:extension>
        <v-tabs v-model="tab">
          <v-tab>
            {{ $i18n.translate("Details") }}
          </v-tab>
          <v-tab v-if="form.id">
            {{ $i18n.translate("Stages") }}
          </v-tab>
          <v-tab v-if="form.id">
            {{ $i18n.translate("Approvers") }}
          </v-tab>
        </v-tabs>
      </template>
    </v-toolbar>
    <v-card-text>
      <ApiError :errors="errors"></ApiError>

      <div class="text-center" v-if="loading">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </div>
      <v-form :value="valid" @submit.prevent="onSubmit" v-if="!loading" v-model="valid">
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-container>
              <v-row>
                <v-col cols="8">
                  <KeyField
                    name="approvalTypeKey"
                    label="Key"
                    id="approvalTypeKey"
                    v-model="form.approvalTypeKey"
                    :disabled="!isEditing"
                    :error-messages="$error.getValidationError(errors, 'approvalTypeKey')"
                    @input="$error.clearValidationError(errors, 'approvalTypeKey')"
                  ></KeyField>
                  <v-text-field
                    name="name"
                    label="Name"
                    id="name"
                    v-model="form.name"
                    :rules="rules.name"
                    :disabled="!isEditing"
                    :error-messages="$error.getValidationError(errors, 'name')"
                    @input="$error.clearValidationError(errors, 'name')"
                    class="required"
                  ></v-text-field>
                  <v-text-field
                    name="description"
                    label="Description"
                    id="description"
                    v-model="form.description"
                    :disabled="!isEditing"
                    :error-messages="$error.getValidationError(errors, 'description')"
                    @input="$error.clearValidationError(errors, 'description')"
                  ></v-text-field>
                  <v-text-field
                    name="approvalKeyPrefix"
                    label="Approval Key Prefix"
                    id="approvalKeyPrefix"
                    v-model="form.approvalKeyPrefix"
                    :rules="rules.approvalKeyPrefix"
                    :disabled="!isEditing"
                    :error-messages="$error.getValidationError(errors, 'approvalKeyPrefix')"
                    @input="$error.clearValidationError(errors, 'approvalKeyPrefix')"
                    class="required"
                  ></v-text-field>
                  <v-switch
                    name="autoAssignSubmitter"
                    id="autoAssignSubmitter"
                    :label="$i18n.translate('Auto Assign') + ' ' + this.$i18n.translate('Submitter')"
                    v-model="form.autoAssignSubmitter"
                    :disabled="!isEditing"
                    :error-messages="$error.getValidationError(errors, 'autoAssignSubmitter')"
                    @input="$error.clearValidationError(errors, 'autoAssignSubmitter')"
                  ></v-switch>
                  <v-switch
                    name="autoApprove"
                    id="autoApprove"
                    :label="$i18n.translate('Auto Approve')"
                    v-model="form.autoApprove"
                    :disabled="!isEditing"
                    :error-messages="$error.getValidationError(errors, 'autoApprove')"
                    @input="$error.clearValidationError(errors, 'autoApprove')"
                  ></v-switch>
                  <ParticipantField
                    showKey
                    v-if="!form.autoAssignSubmitter"
                    name="autoAssignParticipant"
                    id="autoAssignParticipant"
                    v-model="selectedAutoAssignParticipant"
                    :label="$i18n.translate('Auto Assign') + ' ' + this.$i18n.translate('Participant')"
                    :disabled="!isEditing"
                    :error-messages="$error.getValidationError(errors, 'autoAssignParticipant')"
                    @input="$error.clearValidationError(errors, 'autoAssignParticipant')"
                  ></ParticipantField>
                  <EmailTemplateField
                    name="selectedApprovedEmailTemplate"
                    label="Stage Approved Email Template"
                    v-model="selectedApprovedEmailTemplate"
                    :disabled="!isEditing"
                    templateScope="program"
                    :hasSendGridTemplate="true"
                  />
                  <EmailTemplateField
                    name="selectedRejctedEmailTemplate"
                    label="Stage Rejected Email Template"
                    v-model="selectedRejectedEmailTemplate"
                    :disabled="!isEditing"
                    templateScope="program"
                    :hasSendGridTemplate="true"
                  />
                  <EmailTemplateField
                    name="selectedClosedEmailTemplate"
                    label="Stage Closed Email Template"
                    v-model="selectedClosedEmailTemplate"
                    :disabled="!isEditing"
                    templateScope="program"
                    :hasSendGridTemplate="true"
                  />
                  <EmailTemplateField
                    name="selectedChangedStageEmailTemplate"
                    label="Stage Changed Email Template"
                    v-model="selectedChangedStageEmailTemplate"
                    :disabled="!isEditing"
                    templateScope="program"
                    :hasSendGridTemplate="true"
                  />
                  <DateTimePickerField
                    v-model="form.effectiveDate"
                    :disabled="!isEditing"
                    :label="$i18n.translate('Effective Date')"
                    defaultTime="00:00"
                  ></DateTimePickerField>
                  <DateTimePickerField
                    v-model="form.expirationDate"
                    :disabled="!isEditing"
                    :label="$i18n.translate('Expiration Date')"
                    defaultTime="23:59"
                  ></DateTimePickerField>
                </v-col>
                <v-col cols="4">
                  <h2>Information</h2>
                  <ul>
                    <li>
                      An <b>{{ $i18n.translate("Approval Type") }}</b> defines how an
                      {{ $i18n.translate("Approval") }} will operate. It will include
                      {{ $i18n.translate("Approval") }} key prefix and approval stages.
                    </li>
                    <li>
                      The <b>{{ $i18n.translate("Auto Assign") }} {{ $i18n.translate("Submitter") }}</b> will be
                      automatically assigned the {{ $i18n.translate("Approval") }} to the person who submitted for the
                      Approval.
                    </li>
                    <li>
                      The
                      <b>{{ $i18n.translate("Auto Assign") }} {{ $i18n.translate("Participant") }}</b> will be
                      automatically assigned when the {{ $i18n.translate("Approval") }} is initiated. If this is left
                      blank, then there will be no assignment yet.
                    </li>
                    <li>
                      The
                      <b>{{ $i18n.translate("Auto Approve") }}</b> will automatically approve the approval if the
                      submitter is an approver and has the ability to approve and has the necessary limits.
                    </li>
                    <li v-if="form.id">
                      <b>{{ $i18n.translate("Approval Stages") }}</b> are used to identify the different stages an
                      {{ $i18n.translate("Approval") }} must go through to be approved.
                    </li>
                  </ul>
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>
          <v-tab-item v-if="form.id">
            <ApprovalStagesTable
              :approvalType="this.form"
              :disabled="!isEditing"
              v-model="approvalStages"
            ></ApprovalStagesTable>
          </v-tab-item>
          <v-tab-item v-if="form.id">
            <ApprovalApproversTable
              :approvalType="this.form"
              :disabled="!isEditing"
              v-model="approvalApprovers"
            ></ApprovalApproversTable>
          </v-tab-item>
        </v-tabs-items>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import ApiError from "../../display/ApiError.vue";
import DateTimePickerField from "../../fields/DateTimePickerField.vue";
import EmailTemplateField from "../../fields/EmailTemplateField.vue";
import KeyField from "../../fields/KeyField.vue";
import ParticipantField from "../../fields/ParticipantField.vue";
import ApprovalApproversTable from "../../tables/ApprovalApproversTable.vue";
import ApprovalStagesTable from "../../tables/ApprovalStagesTable.vue";

export default {
  name: "ApprovalType",
  metaInfo: {
    title: "Approval Type"
  },
  components: {
    ApiError,
    KeyField,
    ParticipantField,
    DateTimePickerField,
    ApprovalStagesTable,
    ApprovalApproversTable,
    EmailTemplateField
  },
  data: function() {
    return {
      approvalTypeId: 0,
      tab: null,
      valid: false,
      isNew: false,
      isEditing: false,
      loading: false,
      form: {},
      approvalStages: {
        existing: [],
        deleted: []
      },
      approvalApprovers: {
        existing: [],
        deleted: []
      },
      errors: {},
      rules: {
        name: [v => !!v || "Name is required"],
        approvalKeyPrefix: [v => !!v || "Approval Key Prefix is required"]
      },

      selectedAutoAssignParticipant: null,
      selectedApprovedEmailTemplate: null,
      selectedRejectedEmailTemplate: null,
      selectedClosedEmailTemplate: null,
      selectedChangedStageEmailTemplate: null
    };
  },
  methods: {
    onSubmit() {
      this.loading = true;
      this.errors = {};

      let serviceCall = null;

      this.form.autoAssignParticipant = this.selectedAutoAssignParticipant
        ? this.$api.getSelfUrl("participants", this.selectedAutoAssignParticipant)
        : null;

      this.form.approvedEmailTemplate = this.selectedApprovedEmailTemplate
        ? this.$api.getSelfUrl("emailTemplates", this.selectedApprovedEmailTemplate)
        : null;

      this.form.rejectedEmailTemplate = this.selectedRejectedEmailTemplate
        ? this.$api.getSelfUrl("emailTemplates", this.selectedRejectedEmailTemplate)
        : null;

      this.form.closedEmailTemplate = this.selectedClosedEmailTemplate
        ? this.$api.getSelfUrl("emailTemplates", this.selectedClosedEmailTemplate)
        : null;

      this.form.changedStageEmailTemplate = this.selectedChangedStageEmailTemplate
        ? this.$api.getSelfUrl("emailTemplates", this.selectedChangedStageEmailTemplate)
        : null;

      if (this.isNew) {
        serviceCall = this.$api.post("/api/approvalTypes", this.form);
      } else {
        serviceCall = this.$api.patch("/api/approvalTypes/" + this.$route.params.id, this.form);
      }
      serviceCall
        .then(({ data }) => {
          this.form = data;
          this.isEditing = false;
          if (this.isNew) {
            this.isNew = false;
            this.$route.params.id = data.id;
            this.approvalTypeId = data.id;
          }
          this.fetchData();
        })
        .catch(error => {
          this.loading = false;
          this.isEditing = true;
          this.errors = this.$api.getErrorsFromResponse(error);
        });
    },
    onEdit() {
      this.isEditing = !this.isEditing;
      if (!this.isEditing) {
        this.fetchData();
      }
    },
    fetchData() {
      if (!this.isNew) {
        this.loading = true;
        return this.$api
          .get("/api/approvalTypes/" + this.$route.params.id)
          .then(({ data }) => {
            this.form = data;
            return Promise.all([
              this.$api
                .getRelatedObject("autoAssignParticipant", data)
                .then(({ data }) => {
                  this.selectedAutoAssignParticipant = data;
                })
                .catch(() => {
                  console.log("No auto assign participant found");
                }),
              this.$api
                .getRelatedObject("approvedEmailTemplate", data)
                .then(({ data }) => {
                  this.selectedApprovedEmailTemplate = data;
                })
                .catch(() => {
                  console.log("No approved email template found");
                }),
              this.$api
                .getRelatedObject("rejectedEmailTemplate", data)
                .then(({ data }) => {
                  this.selectedRejectedEmailTemplate = data;
                })
                .catch(() => {
                  console.log("No rejected email template found");
                }),
              this.$api
                .getRelatedObject("closedEmailTemplate", data)
                .then(({ data }) => {
                  this.selectedClosedEmailTemplate = data;
                })
                .catch(() => {
                  console.log("No closed email template found");
                }),
              this.$api
                .getRelatedObject("changedStageEmailTemplate", data)
                .then(({ data }) => {
                  this.selectedChangedStageEmailTemplate = data;
                })
                .catch(() => {
                  console.log("No changed stage email template found");
                })
            ]);
          })
          .finally(() => {
            this.loading = false;
            this.breadcrumb();
          });
      } else {
        return Promise.resolve();
      }
    },
    breadcrumb() {
      if (this.isNew) {
        this.$store.dispatch("setBreadcrumb", [
          {
            text: this.$i18n.translate("Dashboard"),
            to: { name: "dashboard" },
            exact: true
          },
          {
            text: this.$i18n.translate("Clients"),
            to: { name: "clients" },
            exact: true
          },
          {
            text: this.selectedClient.name,
            to: {
              name: "client",
              params: { id: this.selectedClient.id },
              exact: true
            }
          },
          {
            text: this.$i18n.translate("Programs"),
            to: { name: "programs" },
            exact: true
          },
          {
            text: this.selectedProgram.name,
            to: {
              name: "program",
              params: { id: this.selectedProgram.id },
              exact: true
            }
          },
          {
            text: "Approval Types",
            to: { name: "approvalTypes" },
            exact: true
          },
          { text: "New Approval Type" }
        ]);
      } else {
        this.$store.dispatch("setBreadcrumb", [
          {
            text: this.$i18n.translate("Dashboard"),
            to: { name: "dashboard" },
            exact: true
          },
          {
            text: this.$i18n.translate("Clients"),
            to: { name: "clients" },
            exact: true
          },
          {
            text: this.selectedClient.name,
            to: {
              name: "client",
              params: { id: this.selectedClient.id },
              exact: true
            }
          },
          {
            text: this.$i18n.translate("Programs"),
            to: { name: "programs" },
            exact: true
          },
          {
            text: this.selectedProgram.name,
            to: {
              name: "program",
              params: { id: this.selectedProgram.id },
              exact: true
            }
          },
          {
            text: "Approval Types",
            to: { name: "approvalTypes" },
            exact: true
          },
          { text: this.form.name }
        ]);
      }
    }
  },
  computed: {
    ...mapGetters(["selectedClient", "selectedProgram"]),
    title() {
      return "Approval Type - " + this.form.name;
    }
  },

  mounted() {
    this.approvalTypeId = parseInt(this.$route.params.id);
    if (this.$route.params.id == 0) {
      this.isNew = true;
      this.isEditing = true;
      this.form.program = this.$api.getSelfUrl("programs", this.selectedProgram);
      this.breadcrumb();
    }

    this.fetchData();
  }
};
</script>
