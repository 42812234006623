<template>
  <div>
    <ApiError :errors="errors"></ApiError>
    <v-data-table :headers="headers" :items="items" :loading="loading" hide-default-footer>
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>Approval Approvers</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom v-if="!disabled">
            <template v-slot:activator="{ on: onTooltip, attrs: attrsTooltip }">
              <v-btn fab small v-bind="{ ...attrsTooltip }" v-on="{ ...onTooltip }" @click="onNewItem()">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>New Approval Approver</span>
          </v-tooltip>
          <v-dialog v-model="dialog" v-if="!disabled && editedItem" width="600">
            <v-form @submit.prevent="onSave" v-model="valid">
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col>
                        <ParticipantField
                          showKey
                          v-model="editedItem.participant"
                          label="Approver"
                          :rules="rules.participant"
                          class="required"
                        ></ParticipantField>
                        <v-text-field
                          v-model="editedItem.name"
                          :rules="rules.name"
                          label="Approver Title"
                          class="required"
                        ></v-text-field>
                        <v-text-field v-model="editedItem.description" label="Approver Description"></v-text-field>
                        <ApprovalStageField
                          v-model="editedItem.approvalStages"
                          label="Approval Stages"
                          hint="User can only approve within these stages"
                          :approvalType="approvalType"
                          multiple
                        />
                        <v-text-field
                          v-model="editedItem.approvalPayoutAmountMinimum"
                          min="0"
                          type="number"
                          label="Approval Payout Amount Minimum"
                        ></v-text-field>
                        <v-text-field
                          v-model="editedItem.approvalPayoutAmountMaximum"
                          min="0"
                          type="number"
                          label="Approval Payout Amount Maximum"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="onClose">{{ $i18n.translate("Cancel") }}</v-btn>
                  <v-btn color="blue darken-1" text :disabled="!valid" @click="onSave">{{
                    $i18n.translate("Save")
                  }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-form>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.approvalPayoutAmountMinimum="{ item }">
        {{ item.approvalPayoutAmountMinimum | toNumber(2) }}
      </template>
      <template v-slot:item.approvalPayoutAmountMaximum="{ item }">
        {{ item.approvalPayoutAmountMaximum | toNumber(2) }}
      </template>
      <template v-slot:item.approvalStages="{ item }">
        <template v-for="(approvalStage, i) in item.approvalStages">
          <v-chip class="ma-1" :key="i">{{ approvalStage.name }}</v-chip>
        </template>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon class="mr-2" v-if="!disabled" @click="onEditItem(item)">
          mdi-pencil
        </v-icon>
        <v-icon v-if="!disabled" @click="onDeleteItem(item)">
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ApiError from "../display/ApiError.vue";
import ApprovalStageField from "../fields/ApprovalStageField.vue";
import ParticipantField from "../fields/ParticipantField.vue";

export default {
  components: { ApiError, ParticipantField, ApprovalStageField },
  name: "ApprovalApproversTable",
  props: {
    approvalType: Object,
    value: Object,
    disabled: Boolean
  },
  data: () => ({
    loading: false,
    errors: {},
    valid: false,
    dialog: false,
    headers: [
      { text: "Approver", value: "participant.fullName" },
      { text: "Approver Title", value: "name" },
      { text: "Approver Description", value: "description" },
      { text: "Stages", value: "approvalStages" },
      { text: "Approval Payout Amount Minimum", value: "approvalPayoutAmountMinimum", align: "right" },
      { text: "Approval Payout Amount Maximum", value: "approvalPayoutAmountMaximum", align: "right" },
      { text: "Actions", value: "actions", sortable: false }
    ],
    rules: {
      name: [v => !!v || "Approver Title is required"],
      participant: [v => !!v || "Approver is required"]
    },
    items: [],
    editedItem: {}
  }),

  computed: {
    ...mapGetters(["selectedProgram", "selectedClient"]),
    formTitle() {
      return this.editedIndex === -1 ? "New Approval Approver" : "Edit Approval Approver";
    }
  },

  watch: {
    approvalType() {
      this.getData();
    }
  },

  created() {
    this.getData();
  },

  methods: {
    getData() {
      if (this.approvalType) {
        this.loading = true;
        return this.$api
          .get("/api/approvalApprovers/byApprovalTypeId/" + this.approvalType.id)
          .then(({ data }) => {
            this.items = data;
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        return Promise.resolve();
      }
    },

    onNewItem() {
      this.editedItem = {};
      this.dialog = true;
    },

    onEditItem(item) {
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    onDeleteItem(item) {
      this.errors = {};
      this.loading = true;
      return this.$api
        .delete("/api/approvalApprovers/" + item.id)
        .then(() => {
          this.getData();
        })
        .catch(error => {
          this.errors = this.$api.getErrorsFromResponse(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onClose() {
      this.dialog = false;
      this.editedItem = null;
    },

    onSave() {
      this.errors = {};
      this.loading = true;
      let service = null;
      let postForm = Object.assign({}, this.editedItem);
      if (postForm.participant && postForm.participant.id) {
        postForm.participant = this.$api.getSelfUrl("participants", postForm.participant);
      }
      if (postForm.approvalStages) {
        postForm.approvalStages = postForm.approvalStages.map(stage => this.$api.getSelfUrl("approvalStages", stage));
      }
      if (postForm.id) {
        service = this.$api.patch("/api/approvalApprovers/" + postForm.id, postForm);
      } else {
        postForm.approvalType = this.$api.getSelfUrl("approvalTypes", this.approvalType);
        service = this.$api.post("/api/approvalApprovers", postForm);
      }
      return service
        .then(() => {
          this.onClose();
          this.getData();
        })
        .catch(error => {
          this.errors = this.$api.getErrorsFromResponse(error);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
